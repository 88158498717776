@media print {
  .dataBlockContainer {
    display: block !important;
  }

  .dataBlock {
    break-inside: avoid;
  }
}

.barRootOk {
  background: #00ff0030 !important;
}

.barColorPrimaryOk {
  background-color: green !important;
}

.barRootWarning {
  background: #ff000030 !important;
}

.barColorPrimaryWarning {
  background-color: red !important;
}

.barRootSuccess {
  background: #0000ff30 !important;
}

.barColorPrimarySuccess {
  background-color: blue !important;
}
