.messageBox {
  border-radius: 1px;
  box-shadow: 0px 2px 5px #00000030;
  border: 1px solid #00000030;
  background-color: white;

  width: 100%;
  display: flex;
  justify-content: center;
  padding: 8px;
}
