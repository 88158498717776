.treeviewbox {
  border-radius: 1px;
  box-shadow: 0px 2px 5px #00000030;
  border: 1px solid #00000030;
  background-color: white;
}

.buttonsBox {
  padding-left: 70px;
  padding-top: 24px;
}

// .tableviewbox {
//   border-radius: 1px;
//   box-shadow: 0px 2px 5px #00000030;
//   border: 1px solid #00000030;
//   background-color: white;
// }
