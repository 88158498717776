.documentIcon {
  color: #006ad4;
}

@media print {
  .dataBlockContainer {
    display: block !important;
  }

  .dataBlock {
    break-inside: avoid;
  }
}
