.title {
  font-weight: bold;
}

.breadcrumbs {
  display: flex;
  & > div {
    padding-right: 1em;
  }
}

.statusFolder {
  display: flex;
}

.statusFolderValue {
  text-transform: capitalize;
  font-weight: bold;
  padding-left: 3px;
  padding-right: 3px;
}
