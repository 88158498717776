.legalFolders {
  padding-left: 0px;
}

.legalFolderLabel {
  min-height: 25px;
}

.link {
  color: #006ad4;
  cursor: pointer;
  &:hover {
    text-decoration: underline;
  }
}

.titleRow {
  display: flex;
  color: #006ad4;
  &:hover {
    text-decoration: underline;
  }
}

.loadMore {
  padding-top: 10px;
  color: #006ad4;

  &:hover {
    text-decoration: underline;
  }
}

.showActive {
  font-weight: bold;
}

.showBoxActive {
  background-color: #f6f7f3; // for selected tab color use: #82916753;
}

.extraLoaded::before {
  content: '...';
  color: gray;
}

.contentRowExpanded {
  padding-bottom: 5px;
}

.treeSideButton {
  font-size: 0.75em !important;
  white-space: nowrap;
}

.newLegalFolderButtonRow {
  padding-bottom: 10px;
}

.newProjectFolderButtonRow {
  padding-bottom: 10px;
  padding-left: 15px;
}

.newProjectDocumentButtonRow {
  padding-bottom: 10px;
  padding-left: 30px;
}
