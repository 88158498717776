.documentsView {
  padding-top: 10px;
}

.messageBox {
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 20px;
  text-align: center;
  color: red;
}
