h6 {
  font-weight: bold !important;
}

hr {
  width: 80%;
  margin: 0 auto !important;
}

.paper-title {
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 1rem !important;
}

.label-title {
  font-weight: bold !important;
  text-transform: uppercase;
}

.label-title-desc {
  font-size: 12px !important;
  text-transform: lowercase;
  color: gray;
}

.label-title-desc-nocase {
  font-size: 12px !important;
  color: gray;
}

.label-title-nocase {
  font-weight: bold !important;
}

.label-title-caption {
  font-size: 0.65rem !important;
}

.MuiSelect-outlined {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font-size: 0.8rem !important;
}

.MuiOutlinedInput-input {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 0.8rem !important;
}

.MuiOutlinedInput-input[readOnly] {
  background-color: #f3f3f3;
}

.MuiInputLabel-root {
  font-size: 0.8rem !important;
}

.MuiInputBase-input {
  font-size: 0.8rem !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}
