.stakeholder-drag-icon {
  vertical-align: bottom;
}

.stakeholder-dragging {
  background-color: #ececec;
}

.stakeholder-dragging .MuiPaper-root {
  opacity: 0.5;
}

.stakeholder-dragged .MuiPaper-root {
  opacity: 1;
}
