// .section {
//   display: flex;
//   justify-content: space-between;
//   width: 100%;
//   gap: 0.5rem;
//   align-items: flex-start;
//   flex-wrap: nowrap;
// }

// .action {
//   display: flex;
//   justify-content: space-between;
//   gap: 1rem;
//   align-items: center;
//   flex-wrap: wrap;
// }

.status {
  font-size: 0.75rem;
  white-space: nowrap;
}

.select {
  width: 16rem;
  padding-top: 4px;
}
