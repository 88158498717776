.upload-container {
    display: grid;
    grid-template-columns: 1fr;
}

.upload-container-message {
    position: relative;
    top: 18px;
}

@media screen and (max-width: 414px) {
    .upload-container {
        grid-template-columns: 1fr;
    }
}