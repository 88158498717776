.gantt_red .bar {
  fill: red !important;
}

.gantt_green .bar {
  fill: green !important;
}

.gantt_blue .bar {
  fill: blue !important;
}

.gantt_orange .bar {
  fill: orange !important;
}

.gantt_yellow .bar {
  fill: yellow !important;
}

.gantt-details-container {
  padding: 0 20px 10px 10px;
  overflow: hidden;
}
