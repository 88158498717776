h6 {
  font-weight: bold !important;
}

hr {
  width: 80%;
  margin: 0 auto !important;
}

.paper-title {
  font-weight: bold !important;
  text-transform: uppercase;
  font-size: 1rem !important;
}

.label-title {
  font-weight: bold !important;
  text-transform: uppercase;
}

.label-title-nocase {
  font-weight: bold !important;
}

.label-title-caption {
  font-size: 0.65rem !important;
}

.MuiSelect-outlined {
  padding-top: 2px !important;
  padding-bottom: 2px !important;
  font-size: 0.8rem !important;
}

.MuiOutlinedInput-input {
  padding-top: 5px !important;
  padding-bottom: 5px !important;
  font-size: 0.8rem !important;
}

.MuiOutlinedInput-input[readOnly] {
  background-color: #f3f3f3;
}

.MuiInputLabel-root {
  font-size: 0.8rem !important;
}

.MuiInputBase-input {
  font-size: 0.8rem !important;
  padding-top: 5px !important;
  padding-bottom: 5px !important;
}

.status-folder {
  position: relative;
  bottom: 4px;
  background-color: #fff;
  border: 1px #999 solid;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 6px;
  margin-right: 6px;
}

.NEGOTIATING {
  background-color: #f2e200;
  border: 1px #f2e200 solid;
}

.APPROVED {
  background-color: #0013bb;
  border: 1px #0013bb solid;
}

.SIGNED {
  background-color: #00bb13;
  border: 1px #00bb13 solid;
}
