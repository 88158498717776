.file-archive-item {
    font-weight: 500;
    text-decoration: underline;
    cursor: pointer;
}

.file-archive-item-readon-only {
    font-weight: 500;
    color: #aaa;
}

.report-section {
    text-overflow: ellipsis;
    overflow: hidden;
}