.stakeholder-draggable {
  cursor: grab;
}

.stakeholder-draggable a {
  color: rgba(0, 0, 0, 0.87);
  cursor: pointer !important;
}

.stakeholder-review-status {
  background-color: #fff;
  border: 1px #999 solid;
  width: 14px;
  height: 14px;
  border-radius: 50%;
  display: inline-block;
  margin-top: 6px;
  margin-right: 6px;
}

.stakeholder-review-status.idle {
  background-color: #fff;
  border: 1px #999 solid;
}

.stakeholder-review-status.pending,
.stakeholder-review-status.review_requested,
.stakeholder-review-status.review_postponed {
  background-color: #f2e200;
  border: 1px #f2e200 solid;
}

.stakeholder-review-status.changesrequested,
.stakeholder-review-status.review_disapproved {
  background-color: #d43624;
  border: 1px #d43624 solid;
}

.stakeholder-review-status.approved,
.stakeholder-review-status.review_approved {
  background-color: #00bb13;
  border: 1px #00bb13 solid;
}
