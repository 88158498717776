.comment-event-profile-image {
    position: relative !important;
    top: -2px !important;
    margin-right: -20px !important;
}

textarea.event-logs-comment-input {
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    width: 100%;
    resize: none;
    padding: 10px;
    border-radius: 4px;
}

.event-comment-log-header-title {
    font-size: 0.9rem;
    padding-right: 12px;
}

.event-comment-log-header-date {
    font-size: 0.75rem;
    position: relative;
    top: 1px;
    color: #888;
}

.comment-event-content-container {
    margin-top: -12px;
}

.comment-event-content {
    position: relative;
    width: 100%;
    top: 10px !important;
    margin-bottom: 5px !important;
}

.comment-event-spacing {
    margin-right: -20px !important;
}

@media screen and (max-width: 375px) {
    .comment-event-profile-image {
        margin-right: 0px !important;
    }

    .comment-event-content {
        left: -10px !important;
    }
}

@media screen and (max-width: 414px) {
    .comment-event-profile-image {
        margin-right: 0px !important;
        padding-right: 10px;
    }

    .comment-event-spacing {
        margin-right: 0px !important;
    }

    .comment-event-content {
        top: 25px !important;
        margin-bottom: 15px !important;
    }
}

@media screen and (max-width: 768px) {
    .comment-event-profile-image {
        margin-right: -45px !important;
        padding-right: 10px;
    }

    .comment-event-spacing {
        margin-right: 0 !important;
    }

    .comment-event-content {
        top: 25px !important;
        margin-bottom: 15px !important;
    }
}

@media screen and (max-width: 1024px) {
    .comment-event-profile-image {
        margin-right: 0px !important;
        padding-right: 10px;
    }

    .comment-event-spacing {
        margin-right: 0 !important;
    }

    .comment-event-content {
        top: 25px !important;
        margin-bottom: 15px !important;
        margin-left: 53px !important;
    }
}