.projectFolders {
  padding-left: 10px;
}

.projectFolderRow {
  padding-bottom: 6px;
  padding-left: 15px;
  display: flex;

  &:hover {
    background-color: #f6f7f3;
  }
}

.documentIcon {
  color: #006ad4;
}

.projectTitle {
  padding-left: 10px;
  color: #006ad4;

  &:hover {
    text-decoration: underline;
  }
}

.loadMore {
  padding-top: 10px;
  color: #006ad4;

  &:hover {
    text-decoration: underline;
  }
}

.showActive {
  font-weight: bold;
}

.icon {
  padding-right: 10px;
  &:hover {
    transform: scale(1.2);
  }
}

.separator {
  border-left: 1px solid #c1c1c1;
}
