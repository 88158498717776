body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: 0.875rem !important;
}

textarea {
  font-family: inherit;
  // display: block;
  vertical-align: top;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.report-section {
  margin-bottom: 34px;
}

.report-title {
  text-transform: uppercase;
  font-size: '1rem' !important;
  font-weight: 800 !important;
  margin-bottom: 15px !important;
}

@media screen and (max-width: 414px) {
  .btn-responsive {
    width: 100% !important;
  }
}

#main-paper {
  color: black;
}

.MuiTab-root {
  color: black !important;
}

.Mui-selected {
  opacity: 1 !important;
}

.dialogGanttChart .ganttChart .gantt-container {
  height: 70vh;
  border: 1px solid lightgray;
}

.gantt-container .popup-wrapper {
  width: fit-content;
  min-width: 200px;
  white-space: nowrap;
  padding: 10px !important;
  background: rgba($color: lightgray, $alpha: 1) !important;
  color: gray !important;
  border: 1px solid gray;
}

.gantt_red .bar {
  fill: red !important;
}

.gantt_green .bar {
  fill: green !important;
}

.gantt_blue .bar {
  fill: blue !important;
}

.gantt_orange .bar {
  fill: orange !important;
}
