.folderTypeTabs {
  border-bottom: 1px solid #e0e0e0;
  background-color: #f6f6f6;
}

.activeTab {
  background-color: #bed0b6 !important;
  font-weight: 750 !important;
  opacity: 1 !important;
}
