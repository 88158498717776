.user-initials {
  border-radius: 50%;
  border: 1px #000 solid;
  background-color: #bed0b6;
  font-weight: bold !important;
  width: 34px;
  height: 34px;
  display: inline-block;
  color: #000;
  text-align: center;
  vertical-align: middle;
}

.user-initials .MuiTypography-body2 {
  display: inline-block;
  font-weight: bold !important;
  margin-top: 0.39rem;
  margin-left: 0.08rem;
}
