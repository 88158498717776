.clientMarkerGreen {
  filter: hue-rotate(240deg);
}

.clientMarkerRed {
  filter: hue-rotate(120deg);
}

.clientMarkerColor {
  filter: hue-rotate(-20deg);
}
