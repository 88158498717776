tbody.MuiTableBody-root > :nth-of-type(1n) {
  background-color: #006ad40d;
  border-bottom: none;
}

tbody.MuiTableBody-root > :nth-of-type(2n) {
  background-color: #ffffff;
  border-bottom: none;
}

tbody.MuiTableBody-root > tr {
  height: 28px;
}

// tbody.MuiTableBody-root> :nth-of-type(1n) td {
//   border-bottom: none;
// }

thead.MuiTableHead-root > :nth-of-type(1) {
  height: 45px;
  white-space: nowrap;
}

thead.MuiTableHead-root > :nth-of-type(2) > th {
  background-color: #ececec;
}

thead.MuiTableHead-root > :nth-of-type(1) .MuiTableCell-root.MuiTableCell-head {
  // text-align: center !important;
  // padding: 8px 0 !important;
  // border-left: 1.2px solid #ccc;
  box-shadow: 0px 1px 3px 0px #ccc;
  // font: normal normal bold 14px/16px 'Segoe UI';
}

.MuiTableRow-root > tr:nth-child(1) > .MuiTableCell-root .MuiTableCell-body {
  background-color: #fff !important;
}

.MuiTableRow-root a {
  // font: normal normal 600 14px/19px 'Segoe UI';
  color: #006ad4;
  opacity: 0.8;
  text-decoration: none;
}

.MuiTableRow-root a:hover {
  text-decoration: underline;
}

.MuiTableRow-root > th {
  // font-size: 13px !important;
  // text-align: left;
  padding: 3px 28px 3px 28px;
}

.MuiTableRow-root > td {
  // font-size: 13px !important;
  // text-align: left;
  padding: 3px 28px;
}

// .MuiTableRow-root > td:nth-child(2),
// .MuiTableRow-root > td:nth-child(3),
// .MuiTableRow-root > td:nth-child(7),
// .MuiTableRow-root > td:nth-child(99) {
//   text-align: left !important;
// }

// .MuiTableRow-root:nth-child(2) td {
//   padding-top: 10px !important;
// }

.MuiTablePagination-root {
  margin: auto !important;
  text-align: center !important;
}

.MuiInputBase-input .MuiInput-input {
  border-bottom: none !important;
}

.report-list-filter-input .MuiInputBase-root.MuiOutlinedInput-root.MuiInputBase-formControl {
  height: 34px !important;
}

// .datagrid_reviewer_holder {
//   display: table-cell;
//   vertical-align: middle;
// }

.datagrid_reviewer {
  display: block;
  // margin-top: 5px;
}

.menuitem_group {
  padding-left: 0 !important;
}

.menuitem_group .MuiListItemText-root span {
  font-weight: bold !important;
  opacity: 75%;
}

.currently_reviewing_column {
  display: flex;
  align-items: center;
}
