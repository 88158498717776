.document-approval-upload-section-header {
  display: grid;
  grid-template-columns: 1fr 70px;
  width: 85%;
}

.document-approval-upload-section-submit {
  display: grid;
  grid-template-columns: 150px 1fr;
  column-gap: 35px;
}

.document-approval-upload-section-submit-message {
  position: relative;
  top: 18px;
}
