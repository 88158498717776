.homeIcon {
  color: #006ad4;
}

.breadcrumbs {
  display: flex;
}

.breadcrumbItem {
  padding-left: 1rem;
  padding-right: 1rem;
}

.separator {
  border-left: 1px solid #c1c1c1;
}

.link {
  color: #006ad4;
  opacity: 0.8;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}
